import React, { Component } from "react";
import { Row, Col } from "react-bootstrap/";
import * as formValidations from "../utils/formValidations";
import FormContacto from "./common/forms/FormContacto";
import * as reportAPI from "../utils/reportAPI";
import StatusForm from "./common/forms/StatusForm";

class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusForm: "Init",
      validation: {
        formValidations,
        file: "Contacto"
      },
      configStatusFormContact: {
        messages: {
          success:
            "¡Se ha Enviado Tu Consulta!, Nos Pondremos en contacto a la Brevedad",
          error: " Ops!, Hubo un Error al enviar el Correo."
        },
        buttons: [
          { title: "Volver", hide: false },
          { title: "Volver a Intentar", hide: false }
        ]
      }
    };
  }

  sendEmail = message => {
    this.setState({ statusForm: "Sending" });

    reportAPI.sendEmail(message).then(res => {
      res.typem === "success" ? this.emailSuccess() : this.emailError();
    });
  };

  emailError = () => {
    this.setState({ statusForm: "Error" });
  };

  emailSuccess = () => {
    this.setState({
      statusForm: "Success"
    });
  };

  backToForm = () => {
    this.setState({
      statusForm: "Init"
    });
  };

  render() {
    const { statusForm, validation, configStatusFormContact } = this.state;

    return (
      <div>
        {statusForm === "Init" ? (
          <Row>
            <Col
              lg={{ span: 6, offset: 3, textAlign: "right" }}
              style={{ paddingTop: "8vh" }}
            >
              Para consultas y/o sugerencias, envia tu consulta
            </Col>

            <Col
              lg={{ span: 6, offset: 3 }}
              style={{ paddingTop: "6vh", paddingBottom: "7vh" }}
            >
              <FormContacto
                setAction={this.sendEmail}
                validation={validation}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col
              lg={{ span: 6, offset: 3 }}
              style={{ paddingTop: "14vh", paddingBottom: "7vh" }}
            >
              <StatusForm
                statusForm={statusForm}
                config={configStatusFormContact}
                setSuccess={this.backToForm}
                setError={this.backToForm}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default Contacto;
