const Idcategorias = [1, 2, 3, 4];
const nombresCategorias = [
  {
    "1": "Legislación",
    "2": "Economica",
    "3": "Contrataciones",
    "4": "Información de otros Organismo Municipales"
  }
];

export const dateFormat = (obj, prop, key) => {
  if (obj[prop].lenght === 0) {
    return;
  }

  obj[prop].forEach(ele => {
    const str = ele[key].split("-");

    const year = Number(str[0]);
    const month = Number(str[1]) - 1;
    const date = Number(str[2]);

    ele[key] = new Date(year, month, date);
  });

  return obj[prop];
};

const addMissingProperties = obj => {
  let fecha = obj[0].id;
  let numeroDefaultBoletin = obj[0].numeroBoletin;

  let objCategories = obj.map(ele => ele.category);
  const missingId = Idcategorias.filter(val => !objCategories.includes(val));

  missingId.forEach((ele, index) => {
    obj.push({
      id: fecha,
      category: ele,
      numeroBoletin: numeroDefaultBoletin,
      archivo: Math.random(),
      fechaBoletin: "",
      url: ""
    });
  });

  return obj;
};

export const addPropTitles = obj => {
  obj.forEach(ele => {
    ele.forEach(ele2 => {
      ele2.title = `${ele2.category}ª Sección: ${
        nombresCategorias[0][ele2.category]
      }`;
    });
    ele.sort((a, b) => a.category - b.category);
  });
  // To Make an Order Decresing by Number
  obj.reverse();
  return obj;
};

export const setObjFormat = obj => {
  const orgs = obj.boletines;
  // Group Elements By Id
  const result = orgs.reduce((result, org) => {
    (result[org.numeroBoletin] = result[org.numeroBoletin] || []).push(org);
    return result;
  }, {});

  let dataTable = [];

  Object.keys(result).forEach(ele => {
    dataTable.push(result[ele]);
  });

  dataTable.forEach((boletin, index) => {
    if (boletin.length < 4) {
      boletin = addMissingProperties(boletin);
    }
  });

  return dataTable;
};
