import React, { Component } from "react";
import Spinner from "react-bootstrap/Spinner";
import * as reportAPI from "../utils/reportAPI";
import * as filters from "../utils/filters";
import * as pagination from "../utils/pagination";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as formValidations from "../utils/formValidations";
import FormBoletin from "./common/forms/FormBoletin";
import Toaster from "./common/Toaster";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import ReactPaginate from "react-paginate";
import * as moment from "moment";
import "moment/locale/es"; // without this line it didn't work
moment.locale("es");

class PublicacionesBoletin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateLoading: false,
      stateReset: true,
      dataTable: [],
      dataTablePaginated: [],
      pageCount: 0,
      criteriaSearch: {},
      messageToaster: "",
      showToaster: false,
      dateTable: [],
      validation: {
        formValidations,
        file: "busquedaBoletin",
        isMulti: formValidations.enabledMulti
      }
    };
  }

  componentDidMount() {
    this.initialSearch();
  }

  initialSearch = () => {
    this.setState({ stateLoading: true });
    const category = "[1, 2, 3, 4]";
    reportAPI
      .searchReport({ category })
      .then(res => {
        this.prepareDataForTable(res);
        this.setState({ stateLoading: false });
      })
      .catch(() => {
        this.setState({ stateLoading: false });
      });
  };

  resetSearch = () => {
    this.initialSearch();
    this.setState({ stateReset: true });
  };

  searchReport = criteria => {
    this.setState({ stateLoading: true });
    const objCriteria = formValidations.prepareSearchReport(criteria);
    return new Promise((resolve, reject) => {
      reportAPI
        .searchReport(objCriteria)
        .then(res => {
          this.prepareDataForTable(res);
          this.setState({ stateReset: false });
          this.setState({ stateLoading: false });
          resolve();
        })
        .catch(() => {
          this.setState({ stateLoading: false });
        });
    });
  };

  prepareDataForTable = res => {
    let dataTable = filters.setObjFormat(res);
    dataTable = filters.addPropTitles(dataTable);
    const pageCount = pagination.setAmoutPages(dataTable);
    const dataTablePaginated = pagination.changePage(dataTable);

    this.setState({ dataTable, dataTablePaginated, pageCount });
  };

  setShowToaster = value => {
    this.setState({ showToaster: value });
  };

  setMessageToaster = (succesToaster, titleHeaderToaster, messageToaster) => {
    this.setState({ succesToaster, titleHeaderToaster, messageToaster }, () => {
      this.setShowToaster(true);
    });
  };

  handlePageClick = data => {
    const { dataTable } = this.state;
    const page = data.selected;
    const dataTablePaginated = pagination.changePage(dataTable, page);
    this.setState({ dataTablePaginated });
  };

  render() {
    const modificarBoletinFormTitles = {
      titleButton: "Buscar"
    };

    const {
      stateLoading,
      stateReset,
      dataTablePaginated,
      pageCount,
      showToaster,
      succesToaster,
      titleHeaderToaster,
      messageToaster,
      validation
    } = this.state;

    return (
      <div>
        <Row>
          <Col
            lg={{ span: 12 }}
            style={{ paddingTop: "8vh", zIndex: "1002", textAlign: "left" }}
          >
            <Accordion>
              <Accordion.Toggle as={Button} variant="dark" eventKey="0">
                Busqueda
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="0">
                <Col lg={{ span: 6, offset: 3 }}>
                  <FormBoletin
                    titles={modificarBoletinFormTitles}
                    setAction={this.searchReport}
                    resetSearch={this.resetSearch}
                    validation={validation}
                    stateReset={stateReset}
                  />
                </Col>
              </Accordion.Collapse>
            </Accordion>
          </Col>
        </Row>

        <Row>
          <Col
            lg={{ span: 6, offset: 3 }}
            style={{ textAlign: "center", paddingTop: "10vh" }}
          >
            {stateLoading === true ? (
              <Spinner
                style={{
                  position: "relative"
                }}
                animation="border"
                variant="dark"
              />
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col lg={{ span: 12 }} style={{ paddingTop: "7vh" }}>
            {dataTablePaginated.length > 0 && stateLoading === false ? (
              dataTablePaginated.map((boletines, index) => (
                <Accordion key={index} defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="warining"
                        eventKey={`${index}`}
                      >
                        <span
                          style={{
                            color: "#ff8c09",
                            fontWeight: "bold",
                            textDecoration: "none"
                          }}
                        >
                          Boletín Número {boletines[0].numeroBoletin} -{" "}
                          {moment(boletines[0].id).format("dddd, LL")}
                        </span>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={`${index}`}>
                      <Card.Body>
                        {boletines.map(ele => (
                          <div key={`id${ele.archivo}`}>
                            {ele.url !== "" ? (
                              <ListGroup defaultActiveKey="#link1">
                                <a
                                  style={{
                                    color: "#4d4c4c",
                                    textDecoration: "none"
                                  }}
                                  href={ele.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <ListGroup.Item
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold"
                                    }}
                                  >
                                    {ele.title}
                                  </ListGroup.Item>
                                </a>
                              </ListGroup>
                            ) : (
                              <ListGroup>
                                <ListGroup.Item variant="light">
                                  {ele.title}
                                </ListGroup.Item>
                              </ListGroup>
                            )}
                          </div>
                        ))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              ))
            ) : (
              <Row>
                {stateLoading === false ? (
                  <Col lg={{ span: 12 }} style={{ textAlign: "center" }}>
                    <div
                      style={{
                        position: "relative",
                        paddingTop: "80px",
                        fontWeight: "bold"
                      }}
                    >
                      No Hay Resultados Disponibles
                    </div>
                  </Col>
                ) : null}
              </Row>
            )}
          </Col>

          <Col
            lg={{ span: 6, offset: 3 }}
            style={{
              paddingTop: "3vh",
              paddingBottom: "7vh",
              zIndex: "1004"
            }}
          >
            <div style={{ position: "relative", marginLeft: "50px" }}>
              {pageCount > 1 && stateLoading === false ? (
                <ReactPaginate
                  previousLabel={"volver"}
                  nextLabel={"siguiente"}
                  breakLabel={"..."}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  activeLinkClassName={"page-item active"}
                  disabledClassName={"page-item disabled"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"page-item active"}
                />
              ) : null}
            </div>
          </Col>
          <Col
            lg={{ span: 12 }}
            style={{ paddingTop: "15vh", paddingBottom: "7vh", zIndex: "1000" }}
          >
            <Toaster
              show={showToaster}
              setShowToaster={this.setShowToaster}
              message={messageToaster}
              success={succesToaster}
              titleHeader={titleHeaderToaster}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default PublicacionesBoletin;
