export const categoryReports = [
  {
    value: "1",
    label: "Legislación",
    color: "#00B8D9",
    isFixed: true
  },
  { value: "2", label: "Economica", color: "#0052CC", isDisabled: false },
  { value: "3", label: "Contrataciones", color: "#5243AA" },
  {
    value: "4",
    label: "Informacion de Organismos Municipales",
    color: "#FF5630",
    isFixed: true
  }
];

//export const api = "http://boletinoficial.local/boletinAPI/publicaciones/";
export const api =
  "https://boletinoficial.villacarlospaz.gov.ar/boletinAPI/publicaciones/";

/*jefaturaoficialia@villacarlospaz.gov.ar
oficialia1@villacarlospaz.gov.ar
oficialia2@villacarlospaz.gov.ar*/
