import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

class MySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animatedComponents: makeAnimated()
    };
  }

  handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange("category", value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    this.props.onBlur("category", true);
  };

  render() {
    const { animatedComponents } = this.state;
    const { options, isMulti } = this.props;

    return (
      <div>
        <Select
          isMulti={isMulti}
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={options}
          value={this.props.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      </div>
    );
  }
}
export default MySelect;
