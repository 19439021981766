import { api } from "../components/common/constants/data";
// Generate a unique token for storing your bookshelf data on the backend server.
let token = localStorage.token;
if (!token)
  token = localStorage.token = Math.random()
    .toString(36)
    .substr(-8);

/*const headers = {
  Accept: "multipart/form-data"
};*/

export const searchReport = report => {
  return fetch(`${api}searchReport`, {
    method: "POST",
    headers: {
      Accept: "multipart/form-data"
    },
    body: JSON.stringify(report)
  }).then(res => res.json());
};

export const sendEmail = message => {
  return fetch(`${api}sendEmail`, {
    method: "POST",
    headers: {
      Accept: "multipart/form-data"
    },
    body: JSON.stringify(message)
  }).then(res => res.json());
};
