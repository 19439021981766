import React from "react";
import { Col, Row, Button, Alert, Spinner } from "react-bootstrap/";

const StatusForm = props => {
  const { statusForm, config, setSuccess, setError } = props;

  return (
    <div>
      {statusForm === "Sending" ? (
        <Row>
          <Col
            lg={{ span: 12 }}
            style={{
              textAlign: "center"
            }}
          >
            <Spinner
              style={{
                position: "relative"
              }}
              animation="border"
              variant="primary"
            />
          </Col>
        </Row>
      ) : null}
      {statusForm === "Success" ? (
        <Row>
          <Col
            lg={{ span: 12 }}
            style={{
              textAlign: "center"
            }}
          >
            <Alert key={"aler1"} variant={"success"}>
              {config.messages.success}
            </Alert>
            <Button
              onClick={setSuccess}
              style={{
                position: "relative"
              }}
              variant="primary"
              size="md"
            >
              {config.buttons[0].title}
            </Button>
          </Col>
        </Row>
      ) : null}
      {statusForm === "Error" ? (
        <Row>
          <Col
            lg={{ span: 12 }}
            style={{
              textAlign: "center"
            }}
          >
            <Alert key={"aler1"} variant={"warning"}>
              {config.messages.error}
            </Alert>
            <Button
              onClick={setError}
              style={{
                position: "relative"
              }}
              variant="primary"
              size="md"
            >
              {config.buttons[1].title}
            </Button>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default StatusForm;
