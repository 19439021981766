const imtesPerPage = 10;

export const setAmoutPages = obj => {
  //console.log(obj.length);

  const ammoutPage = obj.length / imtesPerPage;
  const totalPages = Math.ceil(ammoutPage);
  //console.log("total Pages", totalPages);
  return totalPages;
};

export const changePage = (obj, page = 0) => {
  //  console.log("Page Number Sended", page);
  let posNewPage = imtesPerPage;

  if (page !== 0) {
    page = page * imtesPerPage;
    posNewPage = page + imtesPerPage;
  }

  // console.log("From", page);
  // console.log("To", posNewPage);

  //obj;
  const dataTalbe = obj.slice(page, posNewPage);
  return dataTalbe;
};
