import React from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import * as moment from "moment";

class MyDateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: [
        moment(new Date())
          .startOf("month")
          .toDate(),
        new Date()
      ],
      maxDate: new Date()
    };
  }

  resetDate = () => {
    let date = [
      moment(new Date())
        .startOf("month")
        .toDate(),
      new Date()
    ];
    this.setState({ date });
  };

  handleChange = date => {
    this.setState({ date });
    this.props.onChange("datesReport", date);
  };

  render() {
    let { date, maxDate } = this.state;

    return (
      <div className="">
        <DateRangePicker
          selectRange={true}
          onChange={this.handleChange}
          value={date}
          maxDate={maxDate}
        />
      </div>
    );
  }
}
export default MyDateRangePicker;
