import * as moment from "moment";

export const enabledMulti = true;
export const disabledMulti = false;

export const Contacto = values => {
  const errors = {};

  const validateOnlyLettres = new RegExp("^[A-Za-z ]+$");
  const validateEmail = new RegExp(
    "^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+.)+([A-Za-z0-9]{2,4}|museum)$"
  );

  if (!values.nameLastName) {
    errors.nameLastName = "Debe Ingrear Un Nombre o Apellido";
  } else if (!validateOnlyLettres.test(values.nameLastName)) {
    errors.nameLastName = "El Nombre o Apellido Solo Puede Contener Letras";
  } else if (values.nameLastName.length > 50) {
    errors.nameLastName =
      "El Nombre o Apellido solo Puede Contener Hasta 50 Caracteres";
  } else if (values.nameLastName.length < 3) {
    errors.nameLastName = "El Nombre o Apellido Debe Tener Minimo 3 Caracteres";
  }

  if (!values.userEmail) {
    errors.userEmail = "Debe Ingresar Un email";
  } else if (!validateEmail.test(values.userEmail)) {
    errors.userEmail = "Debe Ingresar Un email Valido";
  }

  if (!values.message) {
    errors.message = "Debe Ingresar Un Mensaje";
  } else if (values.message.length < 25) {
    errors.message = "Debe Ingresar Un Mensaje Al Menos de 25 Caracteres";
  } else if (values.message.length > 200) {
    errors.message = "El Mensaje No Puede Contener Mas de 200 Caracteres";
  }

  return errors;
};

export const prepareSearchReport = (values = {}) => {
  let objCritearia = {};

  if (values.numeroBoletin !== "") {
    objCritearia["numeroBoletin"] = values.numeroBoletin;
  }

  if (
    typeof values.category !== "undefined" &&
    values.category !== "" &&
    values.category !== null
  ) {
    let category = [];

    values.category.forEach((ele, index) => {
      category.push(ele.value);
    });

    objCritearia["category"] = JSON.stringify(category);
  }

  if (values.datesReport !== null) {
    objCritearia["fechaDesde"] = `${moment(values.datesReport[0]).format(
      "DD-MM-YYYY"
    )}`;

    objCritearia["fechaHasta"] = `${moment(values.datesReport[1]).format(
      "DD-MM-YYYY"
    )}`;
  }

  return objCritearia;
};

export const busquedaBoletin = (values = {}) => {
  const errors = {};

  const validateNumber = new RegExp("^\\d+$");

  if (
    values.numeroBoletin !== "" &&
    !validateNumber.test(values.numeroBoletin)
  ) {
    errors.numeroBoletin = "Solo Puede Contener Numeros";
  } else if (values.numeroBoletin.length > 15) {
    errors.numeroBoletin = "Solo Puede Contener Numeros Hasta 15 Digitos";
  }

  let allEmpty = true;

  Object.keys(values).forEach(ele => {
    if (values[ele]) {
      allEmpty = false;
    }
  });

  if (allEmpty) {
    errors.allEmpty =
      "Debe Seleccionar Al menos un Campo Para Realizar la Busqueda";
  }

  return errors;
};
