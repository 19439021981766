import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "../assets/css/App.css";
import Menu from "./common/Menu";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Contacto from "./Contacto";
import PublicacionesBoletin from "./PublicacionesBoletin";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  userRoutes = () => {
    return (
      <Switch>
        <Route exact path="/" component={PublicacionesBoletin} />
        <Route path="/contacto" component={Contacto} />
        <Route path="/publicaciones" component={PublicacionesBoletin} />
      </Switch>
    );
  };

  render() {
    return (
      <div className="App">
        <Menu />
        <Row>
          <Col
            lg={{ span: 12 }}
            style={{
              paddingTop: "8vh",

              textAlign: "center",
              backgroundColor: "white"
            }}
          >
            <div style={{ height: "100px" }}>
              <p className="h4" style={{ position: "relative", top: "35px" }}>
                Boletín Oficial Municipal – Municipalidad de Villa Carlos Paz
              </p>
            </div>
          </Col>
        </Row>
        <Container>
          <Row>
            <Col lg={{ span: 12 }}>{this.userRoutes()}</Col>
          </Row>
          <Row>
            <Col lg={{ span: 12 }}></Col>
          </Row>
        </Container>

        <footer id="sticky-footer" className="py-4 bg-dark text-white-50">
          <div className="container text-center">
            <small className="Footer-contact">
              Municipalidad de Villa Carlos Paz - Liniers 50 (5152) - Villa
              Carlos Paz - Córdoba - Argentina - Contacto Teléfonos:. (03541)
              421801/810 líneas rotativas.
            </small>
          </div>
        </footer>
      </div>
    );
  }
}

export default Main;
