import React from "react";
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const FormContacto = props => {
  //const childRef = useRef();

  const file = props.validation.file;
  const validate = props.validation.formValidations[file];
  let { setAction } = props;

  /*const NewHOC = PassedComponent => {
    return class extends React.Component {
      render() {
        return (
          <div>
            <PassedComponent {...this.props} />
          </div>
        );
      }
    };
  };*/

  //This Should be the body of the form, for future developmet
  //const ComponentButtons = NewHOC(FormButtons);

  const formik = useFormik({
    initialValues: {
      nameLastName: "",
      userEmail: "",
      message: ""
    },
    validate,
    onSubmit: values => {
      setAction(values);
    }
  });

  const resetValuesForm = () => {
    formik.resetForm();
  };

  return (
    <div>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group as={Col} controlId="validationFormik01">
          <Form.Label> Nombre Y Apellido</Form.Label>
          <Form.Control
            name="nameLastName"
            value={formik.values.nameLastName}
            onChange={formik.handleChange}
          />
          {formik.errors.nameLastName ? (
            <div className="errorMessage">{formik.errors.nameLastName}</div>
          ) : null}
        </Form.Group>
        <Form.Group as={Col} controlId="validationFormik02">
          <Form.Label>Correo</Form.Label>
          <Form.Control
            name="userEmail"
            value={formik.values.userEmail}
            onChange={formik.handleChange}
          />

          {formik.errors.userEmail ? (
            <div className="errorMessage">{formik.errors.userEmail}</div>
          ) : null}
        </Form.Group>

        <Form.Group as={Col} controlId="validationFormik03">
          <Form.Label>Mensaje</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            name="message"
            value={formik.values.message}
            onChange={formik.handleChange}
          />

          {formik.errors.message ? (
            <div className="errorMessage">{formik.errors.message}</div>
          ) : null}
        </Form.Group>
        <Form.Group as={Col} controlId="validationFormik03">
          <Button
            type="button"
            onClick={formik.handleSubmit}
            style={{ position: "relative", left: "20px" }}
            className="float-right"
          >
            Enviar Mensaje
          </Button>
          <Button
            type="button"
            onClick={() => {
              resetValuesForm();
            }}
            variant="dark"
            className="float-right"
          >
            Reset
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default FormContacto;
