import React from "react";
import Toast from "react-bootstrap/Toast";

function Toaster(props) {
  const { success, titleHeader, message, show, setShowToaster } = props;

  const renderHeader = success => {
    const [icon, color] = success ? ["done", "green"] : ["error", "red"];

    return (
      <strong style={{ color }}>
        <i className="material-icons" style={{ marginRight: "14px" }}>
          {icon}
        </i>
      </strong>
    );
  };

  return (
    <Toast
      delay={8000}
      autohide
      style={{
        zIndex: "1002",
        position: "fixed",
        top: "100px"
      }}
      onClose={() => {
        setShowToaster(false);
      }}
      show={show}
    >
      <Toast.Header>
        {renderHeader(success)}
        <strong className="mr-auto">{titleHeader}</strong>
      </Toast.Header>
      <Toast.Body
        style={{ color: success ? "green" : "Red", fontWeight: "600" }}
      >
        {message}
      </Toast.Body>
    </Toast>
  );
}
export default Toaster;
