import React, { useRef } from "react";
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { categoryReports } from "../constants/data";
import Select from "./Select";
import DateRangePicker from "./DateRangePicker";
import * as moment from "moment";
import Button from "react-bootstrap/Button";

const FormBoletin = props => {
  const childRef = useRef();

  const file = props.validation.file;
  const validate = props.validation.formValidations[file];
  let { setAction, resetSearch, stateReset } = props;
  const { isMulti } = props.validation;

  /*const NewHOC = PassedComponent => {
    return class extends React.Component {
      render() {
        return (
          <div>
            <PassedComponent {...this.props} />
          </div>
        );
      }
    };
  };*/

  //This Should be the body of the form, for future developmet
  //const ComponentButtons = NewHOC(FormButtons);

  const formik = useFormik({
    initialValues: {
      numeroBoletin: "",
      category: "",
      datesReport: [
        moment(new Date())
          .startOf("month")
          .toDate(),
        new Date()
      ]
    },
    validate,
    onSubmit: values => {
      setAction(values).then(() => {
        formik.setSubmitting(false);
      });
    }
  });

  const resetValuesForm = () => {
    formik.resetForm();
    childRef.current.resetDate();
    resetSearch();
  };

  return (
    <div style={{ position: "relative", top: "30px" }}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group as={Col} controlId="validationFormik01">
          <Form.Label> Numero de Boletin</Form.Label>
          <Form.Control
            name="numeroBoletin"
            value={formik.values.numeroBoletin}
            onChange={formik.handleChange}
          />
          {formik.errors.numeroBoletin ? (
            <div className="errorMessage">{formik.errors.numeroBoletin}</div>
          ) : null}
        </Form.Group>
        <Form.Group as={Col} controlId="validationFormik02">
          <Form.Label>Secciones</Form.Label>

          <Select
            onChange={formik.setFieldValue}
            onBlur={formik.setFieldTouched}
            value={formik.values.category}
            options={categoryReports}
            isMulti={isMulti}
          />
          {formik.errors.category ? (
            <div className="errorMessage">{formik.errors.category}</div>
          ) : null}
        </Form.Group>

        <Form.Group as={Col} controlId="validationFormik03">
          <Form.Label>Fecha</Form.Label>
          <DateRangePicker
            ref={childRef}
            onChange={formik.setFieldValue}
            datesReport={formik.initialValues.datesReport}
          />
          {formik.errors.datesReport ? (
            <div className="errorMessage">{formik.errors.datesReport}</div>
          ) : null}
        </Form.Group>
        {formik.errors.allEmpty ? (
          <div className="errorMessage">{formik.errors.allEmpty}</div>
        ) : null}
      </Form>
      <Button
        type="button"
        disabled={formik.isSubmitting}
        onClick={formik.handleSubmit}
        style={{ position: "relative", left: "20px" }}
        className="float-right"
      >
        Buscar
      </Button>
      <Button
        disabled={stateReset}
        type="button"
        onClick={() => {
          resetValuesForm();
        }}
        variant="dark"
        className="float-right"
      >
        Reset
      </Button>
    </div>
  );
};

export default FormBoletin;
